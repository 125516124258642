<template>
  <div class="card-content detail-content body-payment confirm">
    <div class="header-pc clearfix">
      <div class="inner">
        <h1>
          <a
            :href="
              $router.resolve({
                name: $route.params.shopId ? 'store' : 'store domain',
              }).href
            "
          >
            <div class="logo">
              <img
                v-if="logoShop"
                :src="logoShop"
                width="100%"
                height="100%"
                style="
                  object-fit: contain;
                  width: 200px;
                  height: 50px;
                  object-position: left;
                "
              />
            </div>
          </a>
        </h1>
        <p class="step" style="position: absolute; right: 0; top: 50%">
          <img src="@/assets/img/img_step03.png" alt="STEP3" />
        </p>
      </div>
    </div>
    <div class="dc-header mb-4 position-relative">
      <b-container fluid class="d-flex align-items-center font-title">
        <router-link
          :to="{ name: $route.params.shopId ? 'store' : 'store domain' }"
        >
          <div>ショップ</div>
        </router-link>
        <b-icon icon="chevronRight" class="mx-3"></b-icon>
        <router-link :to="{ name: '' }">
          <div>購入手続き</div>
        </router-link>
      </b-container>
      <p class="bread-drum">
        <img src="../../assets/img/img_step03.png" />
      </p>
    </div>
    <div id="purchase">
      <div class="floatingWrap">
        <div class="row info-order">
          <div class="w-100">
            <section class="confirmContent">
              <div class="row">
                <div class="col-lg-12">
                  <h1 class="ttl01">
                    <span>お客様情報の確認</span>
                  </h1>
                  <div class="inner">
                    <table>
                      <tbody>
                        <tr>
                          <th>お名前</th>
                          <td>
                            {{
                              infoPayment.lastName + " " + infoPayment.firstName
                            }}
                          </td>
                        </tr>
                        <tr>
                          <th>フリガナ</th>
                          <td>
                            {{
                              infoPayment.lastNameKana +
                              " " +
                              infoPayment.firstNameKana
                            }}
                          </td>
                        </tr>
                        <tr>
                          <th>メールアドレス</th>
                          <td>
                            {{ infoPayment.email }}
                          </td>
                        </tr>
                        <tr
                          v-if="
                            infoPayment.user_input ==
                              listUserInput.name_email_phone ||
                            infoPayment.user_input ==
                              listUserInput.name_email_phone_address
                          "
                        >
                          <th>電話番号</th>
                          <td>
                            {{ infoPayment.phone }}
                          </td>
                        </tr>
                        <tr
                          v-if="
                            infoPayment.user_input ==
                            listUserInput.name_email_phone_address
                          "
                        >
                          <th>住所</th>
                          <td>
                            {{ infoPayment.address }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </section>
            <section class="paymentContent">
              <div class="row">
                <div class="col-lg-12">
                  <h1 class="ttl01">
                    <span>レジに進む</span>
                  </h1>
                </div>
              </div>
              <div class="floatingBox sp-only">
                <div class="row">
                  <div class="col-lg-12 titleFloatingBox">
                    <h2>お申込内容</h2>
                  </div>
                </div>
                <div class="floatingBoxInner">
                  <span id="amountContents">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="boxWrap" style="padding: 10px 10px 0">
                          <div style="font-weight: bold; font-size: 16px">
                            {{ infoPayment.content_title }}
                          </div>
                          <div
                            style="
                              color: #900;
                              font-weight: bold;
                              font-size: 16px;
                              text-align: right;
                            "
                          >
                            <span
                              v-if="
                                infoPayment.info_subscription.isSubscription
                              "
                            >
                              ¥{{
                                Math.round(
                                  Number(infoPayment.price_content)
                                ).toLocaleString("ja")
                              }}
                              /{{ infoPayment.info_subscription.addBill
                              }}{{
                                infoPayment.info_subscription.textChange
                              }}（税抜）
                            </span>
                            <span v-else>
                              ¥{{
                                Math.round(
                                  Number(infoPayment.price_content)
                                ).toLocaleString("ja")
                              }}（税抜）
                            </span>
                          </div>
                          <div
                            v-if="infoPayment.info_subscription.explan"
                            style="
                              font-weight: bold;
                              font-size: 14px;
                              border-top: 1px solid #d8d8cb;
                              margin-top: 5px;
                              margin-bottom: 5px;
                              padding-top: 5px;
                            "
                          >
                            <pre>{{
                              infoPayment.info_subscription.explan
                            }}</pre>
                          </div>
                          <div style="clear: both; height: 0"></div>
                          <div class="sub">
                            <dl>
                              <dt>送料・手数料</dt>
                              <dd>
                                {{
                                  Number(infoPayment.fee).toLocaleString("ja")
                                }}円
                                <span class="excludingTax">（税抜）</span>
                              </dd>
                            </dl>
                            <dl>
                              <dt>消費税</dt>
                              <dd>
                                {{
                                  Math.round(
                                    Number(infoPayment.tax)
                                  ).toLocaleString("ja")
                                }}円
                              </dd>
                            </dl>
                            <dl
                              v-if="
                                infoPayment.info_subscription.isSubscription &&
                                infoPayment.info_subscription.trialPeriod > 0
                              "
                            >
                              <dt>お試し期間</dt>
                              <dd>
                                {{
                                  infoPayment.info_subscription.trialPeriod
                                }}日間
                              </dd>
                            </dl>
                            <dl
                              v-if="
                                infoPayment.info_subscription.isSubscription &&
                                infoPayment.info_subscription.billingCount > 0
                              "
                            >
                              <dt>請求回数</dt>
                              <dd>
                                {{
                                  infoPayment.info_subscription.billingCount
                                }}回
                              </dd>
                            </dl>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div col-lg-12>
                        <div class="total">
                          <div class="clearfix" v-if="infoPayment.total_price">
                            <dt>合計</dt>
                            <dd>
                              <span
                                v-if="
                                  infoPayment.info_subscription.isSubscription
                                "
                                class="total-price"
                                >{{
                                  Math.round(
                                    Number(infoPayment.total_price)
                                  ).toLocaleString("ja")
                                }}円 / {{ infoPayment.info_subscription.addBill
                                }}{{
                                  infoPayment.info_subscription.textChange
                                }}（税抜）
                              </span>
                              <span v-else class="total-price"
                                >{{
                                  Math.round(
                                    Number(infoPayment.total_price)
                                  ).toLocaleString("ja")
                                }}円 （税込）</span
                              >
                            </dd>
                          </div>
                          <div
                            class="clearfix"
                            v-if="
                              infoPayment.info_subscription.isSubscription &&
                              infoPayment.info_subscription.initialCost
                            "
                          >
                            <dt>初期費用</dt>
                            <dd>
                              <span class="total-price">{{
                                Number(
                                  infoPayment.info_subscription.initialCost * 1
                                ).toLocaleString("ja")
                              }}</span
                              >円
                              <span class="excludingTax">（税込）</span>
                            </dd>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <p class="notes">
                          分割決済の場合、分割手数料が別途かかります。
                          分割手数料はカード会社によって異なります。
                        </p>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
              <div class="row" v-if="listContent.length > 0">
                <div class="col-lg-12">
                  <div class="inner" style="padding: unset !important">
                    <span v-if="infoPayment.payment_method == 1"
                      >以下、ご希望のお支払い方法を選択してください。（paypal画面が表示されます）</span
                    >
                    <span v-else
                      >以下、「ご注文を確定する」をクリックしてください</span
                    >
                  </div>
                </div>
              </div>
              <p class="btnSubmit">
                <span
                  v-if="infoPayment.payment_method == 1"
                  ref="paypal"
                  id="paypal-button"
                ></span>
                <button v-else @click="paymentCart()" class="formParts btn01">
                  ご注文を確定する
                </button>
                <!-- <PayPal
                  amount="1"
                  currency="USD"
                  :client="credentials"
                  env="sandbox"
                >
                </PayPal> -->
                <button
                  @click="$bvModal.show('loading-payment')"
                  class="submit-form-purchase hide"
                >
                  Submit
                </button>
              </p>
              <p class="btnBack">
                <button @click="goPayment()" class="formParts btn02">
                  修正する
                </button>
              </p>
            </section>
            <div class="row">
              <span id="assentOrderFormProduct"></span>
            </div>
          </div>
        </div>
        <div class="row detail-order pc-only">
          <div>
            <div class="floatingContent">
              <div class="floatingContentInner">
                <div class="floatingBox">
                  <div class="row">
                    <div class="col-lg-12 titleFloatingBox">
                      <h2>お申込内容</h2>
                    </div>
                  </div>
                  <div class="floatingBoxInner">
                    <span id="amountContents">
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="boxWrap" style="padding: 10px 10px 0">
                            <div style="font-weight: bold; font-size: 16px">
                              {{ infoPayment.content_title }}
                            </div>
                            <div
                              style="
                                color: #900;
                                font-weight: bold;
                                font-size: 16px;
                                text-align: right;
                              "
                            >
                              <span
                                v-if="
                                  infoPayment.info_subscription.isSubscription
                                "
                              >
                                ¥{{
                                  Math.round(
                                    Number(infoPayment.price_content)
                                  ).toLocaleString("ja")
                                }}
                                /{{ infoPayment.info_subscription.addBill
                                }}{{
                                  infoPayment.info_subscription.textChange
                                }}（税抜）
                              </span>
                              <span v-else>
                                ¥{{
                                  Math.round(
                                    Number(infoPayment.price_content)
                                  ).toLocaleString("ja")
                                }}（税抜）
                              </span>
                            </div>
                            <div
                              v-if="infoPayment.info_subscription.explan"
                              style="
                                font-weight: bold;
                                font-size: 14px;
                                border-top: 1px solid #d8d8cb;
                                margin-top: 5px;
                                margin-bottom: 5px;
                                padding-top: 5px;
                              "
                            >
                              <pre>{{
                                infoPayment.info_subscription.explan
                              }}</pre>
                            </div>
                            <div style="clear: both; height: 0"></div>
                            <div class="sub">
                              <dl>
                                <dt>送料・手数料</dt>
                                <dd>
                                  {{
                                    infoPayment.fee
                                      ? Number(infoPayment.fee).toLocaleString(
                                          "ja"
                                        )
                                      : 0
                                  }}円
                                  <span class="excludingTax">（税抜）</span>
                                </dd>
                              </dl>
                              <dl>
                                <dt>消費税</dt>
                                <dd>
                                  {{
                                    infoPayment.tax
                                      ? Math.round(
                                          Number(infoPayment.tax)
                                        ).toLocaleString("ja")
                                      : 0
                                  }}円
                                </dd>
                              </dl>
                              <dl
                                v-if="
                                  infoPayment.info_subscription
                                    .isSubscription &&
                                  infoPayment.info_subscription.trialPeriod > 0
                                "
                              >
                                <dt>お試し期間</dt>
                                <dd>
                                  {{
                                    infoPayment.info_subscription.trialPeriod
                                  }}日間
                                </dd>
                              </dl>
                              <dl
                                v-if="
                                  infoPayment.info_subscription
                                    .isSubscription &&
                                  infoPayment.info_subscription.billingCount > 0
                                "
                              >
                                <dt>請求回数</dt>
                                <dd>
                                  {{
                                    infoPayment.info_subscription.billingCount
                                  }}回
                                </dd>
                              </dl>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div col-lg-12>
                          <div class="total">
                            <div class="clearfix">
                              <dt>合計</dt>
                              <dd>
                                <span
                                  v-if="
                                    infoPayment.info_subscription.isSubscription
                                  "
                                  class="total-price"
                                  >{{
                                    Math.round(
                                      Number(infoPayment.total_price)
                                    ).toLocaleString("ja")
                                  }}円 /
                                  {{ infoPayment.info_subscription.addBill
                                  }}{{
                                    infoPayment.info_subscription.textChange
                                  }}（税抜）
                                </span>
                                <span v-else class="total-price"
                                  >{{
                                    infoPayment.total_price
                                      ? Math.round(
                                          Number(infoPayment.total_price)
                                        ).toLocaleString("ja")
                                      : ""
                                  }}円 （税込）</span
                                >
                              </dd>
                            </div>
                            <div
                              class="clearfix"
                              v-if="
                                infoPayment.info_subscription.isSubscription &&
                                infoPayment.info_subscription.initialCost
                              "
                            >
                              <dt>初期費用</dt>
                              <dd>
                                <span class="total-price">{{
                                  Number(
                                    infoPayment.info_subscription.initialCost *
                                      1
                                  ).toLocaleString("ja")
                                }}</span
                                >円
                                <span class="excludingTax">（税込）</span>
                              </dd>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-12">
                          <p class="notes">
                            分割決済の場合、分割手数料が別途かかります。
                            分割手数料はカード会社によって異なります。
                          </p>
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row policyContent">
          <div class="row">
            <div class="col-lg-8 col-sm-12"></div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <b-modal
        id="loading-payment"
        hide-header
        hide-footer
        no-close-on-backdrop
      >
        <div class="text-center" style="margin: 10px">
          <div class="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div class="d-block text-center">
          <div>現在申し込み処理中です。</div>
          <div>次のページが表示されるまで、そのままお待ちください。</div>
        </div>
      </b-modal>
    </div>
    <!-- modal create user -->
    <div>
      <b-modal id="modal-create-user" hide-header hide-footer>
        <div class="d-block text-center">
          <h5>メールアドレスで新規会員登録</h5>
        </div>
        <CInput
          placeholder="受信可能なメールアドレスを入力してください"
          v-model="infoBuyer.email"
          v-bind:class="{
            'error-input': infoBuyer.emailRequired || infoBuyer.emailInvalid,
          }"
        />
        <div class="message-error" v-if="infoBuyer.emailRequired">
          {{ feedback.REQUIRED }}
        </div>
        <div class="message-error" v-if="infoBuyer.emailInvalid">
          {{ feedback.INVALID }}
        </div>
        <div class="d-flex justify-content-center">
          <b-button class="mx-2 mt-3 btn btn-success" @click="createUser()"
            >同意してアカウント作成</b-button
          >
          <b-button
            class="mx-2 mt-3 btn btn-secondary"
            @click="$bvModal.hide('modal-create-user')"
          >
            キャンセル
          </b-button>
        </div>
      </b-modal>
    </div>
    <!-- modal terms -->
    <div>
      <b-modal id="modal-terms" hide-header hide-footer :size="'xl'">
        <div class="d-flex justify-content-between">
          <h5>メールアドレスで新規会員登録</h5>
          <button
            type="button"
            aria-label="Close"
            class="close"
            @click="$bvModal.hide('modal-terms')"
          >
            ×
          </button>
        </div>
        <hr />
        <CTextarea v-model="terms" rows="20" disabled></CTextarea>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Constants } from "../../utils/constants";
import { Api } from "../../utils/http-common.js";
import { Urls } from "../../utils/urls";
// import { StripeElementCard } from "@vue-stripe/vue-stripe";
import paypal from "@/assets/img/paypal.svg";
import { FeedBack } from "@/utils/feedback.js";
import moment from "moment";
// import paypal from "vue-paypal-checkout";
import { loadScript } from "@paypal/paypal-js";
// import axios from "axios";
import no_image from "@/assets/img/logo_default.png";

export default {
  // components: {
  //   StripeElementCard,
  // },
  name: "confirm_payment",
  data() {
    this.publishableKey = process.env.VUE_APP_STRIPE;
    return {
      // credentials: {
      //   sandbox:
      //     "AW9bynigESNslhG0mme0Nx3YrdWH1qsr9BDxzVm3CU6Fq7GZms09uJ6IUk58U23Lr1OG9mHP2LBI8Wkt",
      //   // production: '<production client id>'
      // },
      feedback: FeedBack,
      shopId: localStorage.getItem(Constants.SHOP_ID),
      id: this.$route.params.id,
      info: localStorage.getItem(Constants.TOKEN_USER)
        ? JSON.parse(localStorage.getItem(Constants.NORMAL_USER_INFO))
        : localStorage.getItem(Constants.TOKEN_ADMIN)
        ? JSON.parse(localStorage.getItem(Constants.ADMIN_USER_INFO))
        : "",
      infoBuyer: {
        name: "",
        nameKana: "",
        postalCode: "",
        address: "",
        email: "",
        emailRequired: false,
        emailInvalid: false,
        firstName: "",
        lastName: "",
        firstNameKana: "",
        lastNameKana: "",
      },
      fee: null,
      tax: null,
      totalOrder: null,
      totalPrice: null,
      totalPointUse: 0,
      totalPricePayment: null,
      totalPointBonus: 0,
      rule: "・転売など当社が不都合と判断した場合はご購入をお断りさせていただくことがございます。\n予めご了承ください。\n・購入を完了することにより、これらのサービス規約に同意したものと見なされます。\n",
      payment: {
        method: "",
        number: "",
        interval: "",
        intervalCount: null,
      },
      numPoint: 0,
      paymentType: Constants.PAYMENT_TYPE,
      optionsInterval: Constants.OPTION_INTERVAL,
      optionsPaymentMethod: [],
      listContent: [],
      saveCard: false,
      token: null,
      cardId: null,
      cardType: null,
      cardNumber: null,
      cardExpirationDate: null,
      maxPoint: 0,
      loading: false,
      totalPointOfUser: 0,
      listContentId: [],
      paypal: paypal,
      loadingPaypal: false,
      loadingTransfer: false,
      isToken:
        localStorage.getItem(Constants.TOKEN_USER) ||
        localStorage.getItem(Constants.TOKEN_ADMIN)
          ? true
          : false,
      text_change: "",
      textIntervalCount: "",
      saleMethod: Constants.CONTENT_TYPE,
      terms: "",
      infoPayment: null,
      listUserInput: Constants.USER_INPUT,
      dataHTML: null,
      logoShop: null,
      is_login: localStorage.getItem(Constants.TOKEN_USER) ? true : false,
      keyPayPal: "",
      secretPayPal: "",
      PaypalUrl: Constants.PAYPAL_URL,
    };
  },
  async mounted() {
    const self = this;
    const { onetime, subscription } = self.paymentType;
    self.infoPayment.purchase_credit_card = self.infoPayment
      .purchase_credit_card
      ? self.infoPayment.purchase_credit_card
      : Constants.DOMAIN +
        self.$router.resolve({
          name: self.$route.params.shopId ? "page_thank" : "page_thank domain",
          params: { shopId: self.shopId },
        }).href;
    const {
      firstName,
      lastName,
      lastNameKana,
      firstNameKana,
      payment_method,
      sale_format,
      total_price,
      token,
      cardId,
      cardType,
      cardNumber,
      cardExpirationDate,
      interval,
      intervalCount,
      trial_period,
      initial_cost,
      total_point_bonus,
      content_id,
      purchase_credit_card,
      slag_id,
      content_id_supplies,
      total_cycle,
      purchaseByUrl,
      urlSale,
      purchase_location_url,
      idPage_purchase_location,
    } = self.infoPayment;
    // eslint-disable-next-line no-undef, no-unused-vars
    await Api.userRequestServer
      .get(
        `/${Urls.AUTHOR_CONTENT}/${this.$route.params.id}?shop_id=${this.shopId}`
      )
      .then((response) => {
        if (response.status == 401) {
          localStorage.removeItem(Constants.TOKEN_USER);
          localStorage.removeItem(Constants.USER_TYPE_USER);
          localStorage.removeItem(Constants.EXPIRES_AT_USER);
          localStorage.removeItem(Constants.USER_ID);
          localStorage.removeItem(Constants.EMAIL_USER);
          localStorage.removeItem("popupSend");
          localStorage.removeItem(Constants.NORMAL_USER_INFO);
          localStorage.removeItem(Constants.IS_REMEMBER_USER);
          this.$router.push({
            name: this.$router.currentRoute.params.shopId
              ? "login shop"
              : "login shop domain",
          });
        }
        const { data } = response;
        if (data.success) {
          let authorById = data.data;
          if (authorById && authorById.paypal_id) {
            this.keyPayPal = authorById.paypal_id;
            this.secretPayPal = authorById.paypal_key;
          } else {
            const shop_info =
              localStorage.getItem(Constants.DETAIL_SHOP) &&
              JSON.parse(localStorage.getItem(Constants.DETAIL_SHOP));
            this.keyPayPal = shop_info.paypal_id;
            this.secretPayPal = shop_info.paypal_secret;
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
    const { keyPayPal, secretPayPal } = this;
    let paymentInfo = {};
    if (payment_method == 0) {
      paymentInfo = {
        token: token,
        save_card: false,
        card_id: cardId,
        card_type: cardType,
        card_number: cardNumber,
        card_expiration_date: cardExpirationDate,
        interval:
          sale_format == subscription
            ? interval == 1
              ? "day"
              : interval == 2
              ? "week"
              : interval == 3
              ? "month"
              : "year"
            : "",
        interval_count: sale_format == subscription ? intervalCount : "",
        trial_period_days: trial_period,
        initial_fee: initial_cost,
        total_cycle: total_cycle,
      };
    } else if (payment_method == 1 && sale_format == subscription) {
      paymentInfo = {
        Frequency:
          interval == 1
            ? "Day"
            : interval == 2
            ? "Week"
            : interval == 3
            ? "Month"
            : "Year",
        FrequencyInterval: intervalCount,
        trial_period_days: trial_period,
        initial_fee: initial_cost,
        total_cycle: total_cycle,
      };
    }
    let orderDetail = {
      payment_method: 0,
      content_id: content_id,
      price: total_price,
      point_received: total_point_bonus,
      payment_amount: 200,
      discount: 0,
      point_use: 0,
      url_page_thank: purchase_credit_card,
      slag_id: slag_id,
      content_id_supplies: content_id_supplies,
      points_purchased: 0,
      purchaseByUrl: purchaseByUrl,
      urlSale: urlSale,
    };

    // payment one time
    if (payment_method == 1 && sale_format == onetime) {
      const paypalButton = await loadScript({
        "client-id": keyPayPal,
        currency: "JPY",
        intent: "capture",
        locale: "ja_JP",
        commit: true,
        vault: true,
      });
      paypalButton
        .Buttons({
          style: {
            layout: "vertical",
            size: "responsive",
            color: "gold",
            shape: "rect",
            label: "buynow",
            // tagline: "false",
          },
          // createOrder: async function () {
          createOrder: function (data, actions) {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    currency_code: "JPY",
                    value: Math.floor(total_price),
                  },
                },
              ],
            });
          },
          onApprove: function (data, actions) {
            return actions.order.capture().then(function (details) {
              console.log("data:", details);
              console.log("data:", data);
              // eslint-disable-next-line no-unused-vars
              let orders = {
                info_payment: paymentInfo,
                payment_info: paymentInfo,
                order: {
                  shop_id: parseInt(self.shopId),
                  payment_type:
                    payment_method == 0 && sale_format == onetime
                      ? 1
                      : payment_method == 0 && sale_format == subscription
                      ? 2
                      : payment_method == 1 && sale_format == onetime
                      ? 3
                      : payment_method == 1 && sale_format == subscription
                      ? 4
                      : 5,
                  status_order: 2,
                  total_amount: Math.floor(total_price),
                  total_payment_amount: Math.floor(total_price),
                  total_point_use: 0,
                  total_point_received: total_point_bonus,
                  last_name: lastName,
                  first_name: firstName,
                  last_name_kana: lastNameKana,
                  first_name_kana: firstNameKana,
                  zipcode: "2332442343",
                  address: "兵庫県 神戸市 北区山田町 下谷上中一里山９−１０７",
                  user_info: self.infoPayment,
                },
                order_details: orderDetail,
                payer_id: data.payerID,
                payment_id: details.purchase_units[0].payments.captures[0].id,
                capture_id: details.purchase_units[0].payments.captures[0].id,
                user_id: self.info.id,
                content_id: self.infoPayment.content_id,
                shop_id: parseInt(self.shopId),
                token: data.facilitatorAccessToken,
                //log_data
                title: self.$route.meta.title,
                add_cart_url: window.location.origin + self.$route.fullPath,
                purchase_location_url: purchase_location_url,
                idPage_purchase_location: idPage_purchase_location,
              };
              // eslint-disable-next-line no-unused-vars
              let inforThanksPage = {
                name: lastName + " " + firstName,
                furigana: lastNameKana + " " + firstNameKana,
                email: self.infoPayment.email,
                content: self.infoPayment.content_title,
                sale_format: sale_format,
                price: total_price,
                url: urlSale,
                fee: self.infoPayment.fee,
                tax: self.infoPayment.tax,
                trialPeriod:
                  self.infoPayment.info_subscription &&
                  self.infoPayment.info_subscription.trialPeriod,
                billingCount:
                  self.infoPayment.info_subscription &&
                  self.infoPayment.info_subscription.billingCount,
                paymentMethod:
                  payment_method == 0
                    ? "クレジットカード"
                    : payment_method == 1
                    ? "paypal"
                    : "銀行振込",
              };
              return new Promise((resolve, reject) => {
                let url_excute = `/${Urls.EX_PAYPAL_ONETIME}`;
                Api.userRequestServer
                  .post(url_excute, orders, {
                    headers: {
                      Authorization:
                        "Bearer " + localStorage.getItem(Constants.TOKEN_USER),
                    },
                  })
                  .then((res) => {
                    if (res.data.success) {
                      if (purchase_credit_card.includes("fixed-page")) {
                        localStorage.setItem(
                          Constants.INFOR_ORDER,
                          JSON.stringify(inforThanksPage)
                        );
                      }
                      window.location.href = purchase_credit_card;
                    } else {
                      self.$store.commit("set", ["message", res.data.message]);
                      self.$store.commit("set", ["error", true]);
                    }
                    resolve(res);
                  })
                  .catch((error) => {
                    console.log(error);
                    reject(error);
                  });
              });
            });
          },
          onError: function (err) {
            console.log(err);
          },
        })
        .render("#paypal-button");
    } else {
      // paypal subscription
      const paypalButton = await loadScript({
        "client-id": keyPayPal,
        currency: "JPY",
        intent: "subscription",
        locale: "ja_JP",
        commit: true,
        vault: true,
      });
      paypalButton
        .Buttons({
          style: {
            layout: "vertical",
            size: "responsive",
            color: "gold",
            shape: "rect",
            label: "buynow",
            // tagline: "false",
          },
          createSubscription: async function (data, actions) {
            // create product paypal
            const contentInfo = {
              name: "cdea_id_" + content_id,
              description: "content cdea",
              type: "DIGITAL",
            };
            const productId = await fetch(
              self.PaypalUrl + "/v1/catalogs/products",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization:
                    "Basic " + btoa(keyPayPal + ":" + secretPayPal),
                },
                body: JSON.stringify(contentInfo),
              }
            )
              .then((response) => response.json())
              .then(async (data) => {
                console.log(data);
                return data.id;
              })
              .catch(function (error) {
                let edata = error.message;
                console.log("Error:", edata);
                self.$store.commit("set", ["message", "支払いに失敗しました"]);
                self.$store.commit("set", ["error", true]);
                return "Error";
              });
            if (productId === "Error") {
              return;
            }
            // create plan
            let billing_cycles = [];
            // add trial billing cycle
            let sequence = 1;
            if (paymentInfo.trial_period_days > 0) {
              billing_cycles = [
                {
                  frequency: {
                    interval_unit: "DAY",
                    interval_count: paymentInfo.trial_period_days,
                  },
                  tenure_type: "TRIAL",
                  sequence: sequence,
                  total_cycles: 1,
                  pricing_scheme: {
                    fixed_price: {
                      value: 0,
                      currency_code: "JPY",
                    },
                  },
                },
              ];
              sequence += 1;
            }

            // add reglar billing cycle first
            let checkInitialFee = false;
            if (Math.floor(paymentInfo.initial_fee)) {
              billing_cycles.push({
                frequency: {
                  interval_unit: paymentInfo.Frequency,
                  interval_count: paymentInfo.FrequencyInterval,
                },
                tenure_type: "TRIAL",
                sequence: sequence,
                total_cycles: 1,
                pricing_scheme: {
                  fixed_price: {
                    value:
                      Math.floor(total_price) +
                      Math.floor(paymentInfo.initial_fee),
                    currency_code: "JPY",
                  },
                },
              });
              sequence += 1;
              checkInitialFee = true;
            }

            // add the 2nd regular billing cycle onwards.
            let total_cycle = paymentInfo.total_cycle
              ? Math.floor(paymentInfo.total_cycle)
              : 0;

            if (total_cycle == 0) {
              billing_cycles.push({
                frequency: {
                  interval_unit: paymentInfo.Frequency,
                  interval_count: paymentInfo.FrequencyInterval,
                },
                tenure_type: "REGULAR",
                sequence: sequence,
                total_cycles: total_cycle,
                pricing_scheme: {
                  fixed_price: {
                    value: Math.floor(total_price),
                    currency_code: "JPY",
                  },
                },
              });
            } else if (total_cycle == 1 && !checkInitialFee) {
              billing_cycles.push({
                frequency: {
                  interval_unit: paymentInfo.Frequency,
                  interval_count: paymentInfo.FrequencyInterval,
                },
                tenure_type: "REGULAR",
                sequence: sequence,
                total_cycles: total_cycle,
                pricing_scheme: {
                  fixed_price: {
                    value: Math.floor(total_price),
                    currency_code: "JPY",
                  },
                },
              });
            } else if (total_cycle > 1) {
              if (checkInitialFee) total_cycle = total_cycle - 1;
              billing_cycles.push({
                frequency: {
                  interval_unit: paymentInfo.Frequency,
                  interval_count: paymentInfo.FrequencyInterval,
                },
                tenure_type: "REGULAR",
                sequence: sequence,
                total_cycles: total_cycle,
                pricing_scheme: {
                  fixed_price: {
                    value: Math.floor(total_price),
                    currency_code: "JPY",
                  },
                },
              });
            }
            if (billing_cycles.length == 2) {
              billing_cycles[1].tenure_type = "REGULAR";
            }
            const planCreate = {
              product_id: productId,
              name: "cdea plan",
              status: "ACTIVE",
              billing_cycles: billing_cycles,
              payment_preferences: {
                auto_bill_outstanding: false,
                // setup_fee: {
                //   value: Math.floor(paymentInfo.initial_fee),
                //   currency_code: "JPY",
                // },
                setup_fee_failure_action: "CANCEL",
                payment_failure_threshold: 1,
              },
            };
            const planId = await fetch(self.PaypalUrl + "/v1/billing/plans", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Basic " + btoa(keyPayPal + ":" + secretPayPal),
              },
              body: JSON.stringify(planCreate),
            })
              .then((response) => response.json())
              .then(async (data) => {
                console.log(data);
                return data.id;
              })
              .catch(function (error) {
                let edata = error.message;
                console.log("Error:", edata);
                self.$store.commit("set", ["message", "支払いに失敗しました"]);
                self.$store.commit("set", ["error", true]);
                return "Error";
              });
            if (planId === "Error") {
              return;
            }
            return actions.subscription.create({
              plan_id: planId, // Creates the subscription
            });
          },
          onApprove: async function (data) {
            console.log(data);
            self.$store.commit("set", ["modalShow", true]);
            let orders = {
              info_payment: paymentInfo,
              payment_info: paymentInfo,
              order: {
                shop_id: parseInt(self.shopId),
                payment_type:
                  payment_method == 0 && sale_format == onetime
                    ? 1
                    : payment_method == 0 && sale_format == subscription
                    ? 2
                    : payment_method == 1 && sale_format == onetime
                    ? 3
                    : payment_method == 1 && sale_format == subscription
                    ? 4
                    : 5,
                status_order: 2,
                total_amount: Math.floor(total_price),
                total_payment_amount: Math.floor(total_price),
                total_point_use: 0,
                total_point_received: total_point_bonus,
                last_name: lastName,
                first_name: firstName,
                last_name_kana: lastNameKana,
                first_name_kana: firstNameKana,
                zipcode: "2332442343",
                address: "兵庫県 神戸市 北区山田町 下谷上中一里山９−１０７",
                user_info: this.infoPayment,
              },
              order_details: orderDetail,
              payer_id: data.payerID,
              payment_id: data.orderID,
              subscriptionID: data.subscriptionID,
              user_id: self.info.id,
              content_id: self.infoPayment.content_id,
              shop_id: parseInt(self.shopId),
              token: data.facilitatorAccessToken,
              //log_data
              title: self.$route.meta.title,
              add_cart_url: window.location.origin + self.$route.fullPath,
              purchase_location_url: purchase_location_url,
              idPage_purchase_location: idPage_purchase_location,
            };
            let inforThanksPage = {
              name: lastName + " " + firstName,
              furigana: lastNameKana + " " + firstNameKana,
              email: self.infoPayment.email,
              content: self.infoPayment.content_title,
              sale_format: sale_format,
              price: total_price,
              url: urlSale,
              fee: self.infoPayment.fee,
              tax: self.infoPayment.tax,
              trialPeriod:
                self.infoPayment.info_subscription &&
                self.infoPayment.info_subscription.trialPeriod,
              billingCount:
                self.infoPayment.info_subscription &&
                self.infoPayment.info_subscription.billingCount,
              paymentMethod:
                payment_method == 0
                  ? "クレジットカード"
                  : payment_method == 1
                  ? "paypal"
                  : "銀行振込",
            };
            // return new Promise((resolve, reject) => {
            let url_excute = `/${Urls.EX_PAYPAL_SUBCRIPTION}`;
            Api.userRequestServer
              .post(url_excute, orders, {
                headers: {
                  Authorization:
                    "Bearer " + localStorage.getItem(Constants.TOKEN_USER),
                },
              })
              .then((res) => {
                if (res.data.success) {
                  self.$store.commit("set", ["modalShow", false]);
                  if (purchase_credit_card.includes("fixed-page")) {
                    localStorage.setItem(
                      Constants.INFOR_ORDER,
                      JSON.stringify(inforThanksPage)
                    );
                  }
                  window.location.href = purchase_credit_card;
                } else {
                  self.$store.commit("set", ["message", res.data.message]);
                  self.$store.commit("set", ["error", true]);
                }
                // resolve(res);
              })
              .catch((error) => {
                console.log(error);
                self.$store.commit("set", ["modalShow", false]);
                // reject(error);
              });
            // });
          },
          onError: function (err) {
            console.log(err);
          },
        })
        .render("#paypal-button");
    }
    this.$store.commit("set", ["modalShow", false]);
  },
  computed: {
    ...mapGetters([
      "success",
      "message",
      "error",
      "listCart",
      "pointOfUser",
      "detailStore",
      "ListSettingShop",
      "refer_url",
    ]),
  },
  async created() {
    this.$store.commit("set", ["modalShow", true]);
    this.$store.commit("set", ["isShowMenu", "none"]);
    const { params } = this.$route;
    const { id } = params;
    if (this.$route.query.info) {
      this.infoPayment = JSON.parse(this.$route.query.info);
    }
    if (this.is_login) {
      this.getListSettingShopUser({
        domain: Constants.DOMAIN,
        shop_id: parseInt(localStorage.getItem(Constants.SHOP_ID)),
      });
    }
    await this.getDetailStore({ shopId: this.shopId, id });
    if (this.isToken) {
      this.getUserUsablePoint(this.shopId);
      this.infoBuyer.name = this.info.last_name + " " + this.info.first_name;
      this.infoBuyer.postalCode = this.info.postal_code;
      this.infoBuyer.address = this.info.address;
    }
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["success", false]);
        this.$store.commit("set", ["message", ""]);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    detailStore() {
      if (this.detailStore && this.detailStore.length > 0) {
        this.dataHTML = this.detailStore[0].sale_other.newsletters;
        this.listContent.push(this.detailStore[0]);
        if (this.listContent.length > 0) {
          this.listContentId = this.listContent.map((item) => item.content_id);
          this.totalOrder = this.listContent.length;
          this.listContent.forEach((element) => {
            this.infoBuyer.firstName = element.info_user.first_name;
            this.infoBuyer.lastName = element.info_user.last_name;
            if (
              element.sale_method == this.saleMethod.pre_sale &&
              element.pre_sale_start_date <=
                moment(String(new Date())).format("YYYY-MM-DD HH:mm:ss") &&
              element.pre_sale_end_date >=
                moment(String(new Date())).format("YYYY-MM-DD HH:mm:ss")
            ) {
              element.product_price = element.pre_sale_price;
            }
            this.terms = element.terms;
            element.payment_sub_default =
              typeof element.payment_sub_default == "string"
                ? JSON.parse(element.payment_sub_default)
                : element.payment_sub_default;
            element.payment_setting =
              typeof element.payment_setting == "string"
                ? JSON.parse(element.payment_setting)
                : element.payment_setting;
            if (element.sale_format == 0) {
              element.payment_setting.forEach((item) => {
                if (item == 0) {
                  this.optionsPaymentMethod.push({
                    label: "クレジットカード",
                    value: 0,
                  });
                } else if (item == 1) {
                  this.optionsPaymentMethod.push({
                    label: "paypal",
                    value: 1,
                  });
                } else if (item == 2) {
                  this.optionsPaymentMethod.push({
                    label: "銀行振込",
                    value: 2,
                  });
                }
              });
            } else if (element.sale_format == 1) {
              element.payment_sub_default.forEach((item) => {
                if (item == 0) {
                  this.optionsPaymentMethod.push({
                    label: "クレジットカード",
                    value: 0,
                  });
                } else if (item == 1) {
                  this.optionsPaymentMethod.push({
                    label: "paypal",
                    value: 1,
                  });
                }
              });
            }
            this.totalPrice =
              element.product_price / (1 + element.tax_rate * 0.01);
            this.totalPointBonus += element.point_bonus.total_point_bonus;
            this.payment.interval = element.change_billing_interval;
            this.text_change =
              element.change_billing_interval == 1
                ? "日"
                : element.change_billing_interval == 2
                ? "週間"
                : element.change_billing_interval == 3
                ? "ヶ月"
                : "年";
            this.payment.intervalCount = element.billing_interval;
            this.textIntervalCount =
              element.billing_interval + this.text_change + "に1回請求する";
            this.tax = element.product_price - this.totalPrice;
            this.fee = element.shipping;
            this.totalPricePayment = element.product_price + this.fee;
          });
          this.maxPoint = this.totalPricePayment;
        }
      }
    },
    "infoBuyer.email"() {
      this.infoBuyer.emailRequired = false;
      this.infoBuyer.emailInvalid = false;
    },
    ListSettingShop() {
      if (this.ListSettingShop && this.ListSettingShop.length > 0) {
        if (this.ListSettingShop[0].logo != null) {
          this.logoShop =
            process.env.VUE_APP_ROOT_BACKEND +
            "/" +
            this.ListSettingShop[0].logo;
        } else {
          this.logoShop = no_image;
        }
      } else {
        this.logoShop = no_image;
      }
    },
  },
  methods: {
    ...mapActions({ getUserUsablePoint: "getUserUsablePoint" }),
    ...mapActions({ getListCart: "getListCart" }),
    ...mapActions({ getShopById: "getShopById" }),
    ...mapActions({
      getDetailStore: "getDetailStore",
      getListSettingShopUser: "getListSettingShopUser",
    }),
    moment,
    addPointUse() {
      if (this.numPoint) {
        if (parseInt(this.numPoint) > this.maxPoint) {
          this.$toasted.error(
            "合計注文金額より多くポイントを使用することはできません。"
          );
        } else if (parseInt(this.numPoint) > this.pointOfUser) {
          this.$toasted.error("ポイントが不足しています。");
        } else {
          this.totalPointUse = parseInt(this.numPoint);
          this.totalPricePayment =
            this.totalPrice - parseInt(this.numPoint) + this.tax + this.fee;
        }
      }
    },
    tokenCreated(token) {
      this.token = token.id;
      let card = token.card;
      this.cardId = card.id;
      this.cardType = card.brand;
      this.cardNumber = card.last4;
      this.cardExpirationDate =
        card.exp_year + ("00" + card.exp_month).substr(-2) + "01";
      if (token) {
        this.$bvModal.show("confirm-payment");
      }
    },
    submit() {
      if (this.isToken) {
        this.$refs.elementRef.submit();
      } else {
        this.$bvModal.show("modal-create-user");
      }
    },
    paymentCart() {
      this.$bvModal.show("loading-payment");
      const { onetime, subscription } = this.paymentType;
      const {
        firstName,
        lastName,
        lastNameKana,
        firstNameKana,
        email,
        content_title,
        payment_method,
        sale_format,
        total_price,
        fee,
        token,
        cardId,
        cardType,
        cardNumber,
        cardExpirationDate,
        interval,
        intervalCount,
        trial_period,
        initial_cost,
        total_point_bonus,
        content_id,
        purchase_credit_card,
        bank_transfer,
        slag_id,
        content_id_supplies,
        total_cycle,
        purchaseByUrl,
        urlSale,
        purchase_location_url,
        idPage_purchase_location,
      } = this.infoPayment;
      let paymentInfo = {};
      if (payment_method == 0) {
        paymentInfo = {
          token: token,
          save_card: false,
          card_id: cardId,
          card_type: cardType,
          card_number: cardNumber,
          card_expiration_date: cardExpirationDate,
          interval:
            sale_format == subscription
              ? interval == 1
                ? "day"
                : interval == 2
                ? "week"
                : interval == 3
                ? "month"
                : "year"
              : "",
          interval_count: sale_format == subscription ? intervalCount : "",
          trial_period_days: trial_period,
          initial_fee: initial_cost,
          total_cycle: total_cycle,
        };
      } else if (payment_method == 1 && sale_format == subscription) {
        paymentInfo = {
          Frequency:
            interval == 1
              ? "Day"
              : interval == 2
              ? "Week"
              : interval == 3
              ? "Month"
              : "Year",
          FrequencyInterval: intervalCount,
          trial_period_days: trial_period,
          initial_fee: initial_cost,
          total_cycle: total_cycle,
        };
      }
      let orderDetails = [];
      let orderDetail = {
        payment_method: 0,
        content_id: content_id,
        price: total_price,
        point_received: total_point_bonus,
        payment_amount: 200,
        discount: 0,
        point_use: 0,
        url_page_thank: purchase_credit_card,
        slag_id: slag_id,
        content_id_supplies: content_id_supplies,
        purchaseByUrl: purchaseByUrl,
        urlSale: urlSale,
        trialPeriod: trial_period,
        billingCount: total_cycle,
      };
      orderDetails.push(orderDetail);
      const orders = {
        info_payment: paymentInfo,
        payment_info: paymentInfo,
        order: {
          shop_id: parseInt(this.shopId),
          payment_type:
            payment_method == 0 && sale_format == onetime
              ? 1
              : payment_method == 0 && sale_format == subscription
              ? 2
              : payment_method == 1 && sale_format == onetime
              ? 3
              : payment_method == 1 && sale_format == subscription
              ? 4
              : 5,
          status_order: 2,
          total_amount: Math.floor(total_price),
          total_payment_amount: Math.floor(total_price),
          total_point_use: 0,
          total_point_received: total_point_bonus,
          last_name: lastName,
          first_name: firstName,
          last_name_kana: lastNameKana,
          first_name_kana: firstNameKana,
          zipcode: "2332442343",
          address: "兵庫県 神戸市 北区山田町 下谷上中一里山９−１０７",
          user_info: this.infoPayment,
        },
        order_details: orderDetails,
        //log_data
        title: this.$route.meta.title,
        add_cart_url: window.location.origin + this.$route.fullPath,
        purchase_location_url: purchase_location_url,
        idPage_purchase_location: idPage_purchase_location,
      };
      const inforThanksPage = {
        name: lastName + " " + firstName,
        furigana: lastNameKana + " " + firstNameKana,
        email: email,
        content: content_title,
        sale_format: sale_format,
        price: total_price,
        url: urlSale,
        fee: fee,
        tax: this.infoPayment.tax,
        trialPeriod:
          this.infoPayment.info_subscription &&
          this.infoPayment.info_subscription.trialPeriod,
        billingCount:
          this.infoPayment.info_subscription &&
          this.infoPayment.info_subscription.billingCount,
        paymentMethod:
          payment_method == 0
            ? "クレジットカード"
            : payment_method == 1
            ? "paypal"
            : "銀行振込",
      };

      const api = localStorage.getItem(Constants.TOKEN_USER)
        ? Api.userRequestServer
        : Api.adminRequestServer;
      if (payment_method == 0 && sale_format == onetime) {
        const url = `/${Urls.ADMIN}/${Urls.ORDER}/${Urls.CREATE}`;
        api
          .post(url, orders)
          .then((response) => {
            const { data } = response;
            if (data.success) {
              this.$toasted.success(data.message);
              this.$bvModal.hide("loading-payment");
              this.getListCart({
                shop_id: this.shopId,
              });
              if (purchase_credit_card) {
                if (purchase_credit_card.includes("fixed-page")) {
                  localStorage.setItem(
                    Constants.INFOR_ORDER,
                    JSON.stringify(inforThanksPage)
                  );
                }
                window.location.href = purchase_credit_card;
              } else {
                this.$router.push({
                  name: this.$route.params.shopId
                    ? "page_thank"
                    : "page_thank domain",
                  params: { shopId: this.shopId },
                });
              }
            } else {
              this.$bvModal.hide("loading-payment");
              this.$toasted.clear();
              this.$toasted.error(data.message);
            }
          })
          .catch((error) => {
            if (error.response.status == 500) {
              this.$bvModal.hide("loading-payment");
              this.$toasted.error(
                "決済時にシステムエラーが発生しました。再度お試しください。"
              );
            }
          });
      } else if (payment_method == 1 && sale_format == onetime) {
        const url = `/${Urls.PAYPAL}/${Urls.ONETIME_REDIRECT}`;
        api
          .post(url, orders)
          .then((response) => {
            const { data } = response;
            if (data.success == false) {
              this.$bvModal.hide("loading-payment");
              this.$toasted.error(data.message);
            } else {
              this.$bvModal.hide("loading-payment");
              window.location.href = data;
            }
          })
          .catch((error) => {
            if (error.response.status == 500) {
              this.$bvModal.hide("loading-payment");
              this.$toasted.error(
                "決済時にシステムエラーが発生しました。再度お試しください。"
              );
            }
          });
      } else if (payment_method == 0 && sale_format == subscription) {
        const url = `/${Urls.STRIPE}/${Urls.CREATE_PLAN}`;
        api
          .post(url, orders)
          .then((response) => {
            const { data } = response;
            if (data.success && data.data) {
              this.$bvModal.hide("loading-payment");
              this.$toasted.success(data.message);
              this.getListCart({
                shop_id: this.shopId,
              });
              if (purchase_credit_card) {
                if (purchase_credit_card.includes("fixed-page")) {
                  localStorage.setItem(
                    Constants.INFOR_ORDER,
                    JSON.stringify(inforThanksPage)
                  );
                }
                window.location.href = purchase_credit_card;
              } else {
                this.$router.push({
                  name: this.$route.params.shopId
                    ? "page_thank"
                    : "page_thank domain",
                  params: { shopId: this.shopId },
                });
              }
            } else {
              this.$bvModal.hide("loading-payment");
              this.$toasted.error(data.message);
            }
          })
          .catch((error) => {
            if (error.response.status == 500) {
              this.$bvModal.hide("loading-payment");
              this.$toasted.error(
                "決済時にシステムエラーが発生しました。再度お試しください。"
              );
            }
          });
      } else if (payment_method == 1 && sale_format == subscription) {
        const url = `/${Urls.PAYPAL}/${Urls.CREATE_PLAN}`;
        api
          .post(url, orders)
          .then((response) => {
            const { data } = response;
            if (data.success == false) {
              this.$bvModal.hide("loading-payment");
              this.$toasted.error(data.message);
            } else {
              this.$bvModal.hide("loading-payment");
              window.location.href = data;
            }
          })
          .catch((error) => {
            if (error.response.status == 500) {
              this.$bvModal.hide("loading-payment");
              this.$toasted.error(
                "決済時にシステムエラーが発生しました。再度お試しください。"
              );
            }
          });
      } else if (payment_method == 2) {
        const url = `/${Urls.TRANSFER}`;
        api
          .post(url, orders)
          .then((response) => {
            const { data } = response;
            if (data.success) {
              this.$bvModal.hide("loading-payment");
              this.$toasted.success(data.message);
              this.getListCart({
                shop_id: this.shopId,
              });
              if (bank_transfer) {
                if (bank_transfer.includes("fixed-page")) {
                  localStorage.setItem(
                    Constants.INFOR_ORDER,
                    JSON.stringify(inforThanksPage)
                  );
                }
                window.location.href = bank_transfer;
              } else {
                this.$router.push({
                  name: this.$route.params.shopId
                    ? "page_thank"
                    : "page_thank domain",
                  params: { shopId: this.shopId },
                });
              }
            } else {
              this.$toasted.error(data.message);
              this.$bvModal.hide("loading-payment");
            }
          })
          .catch((error) => {
            if (error.response.status == 500) {
              this.$bvModal.hide("loading-payment");
              this.$toasted.error(
                "決済時にシステムエラーが発生しました。再度お試しください。"
              );
            }
          });
      }
    },
    redirectLibrary() {
      this.$router.push({
        name: this.$route.params.shopId ? "library" : "library domain",
      });
    },
    createUser() {
      if (!this.infoBuyer.email) {
        this.infoBuyer.emailRequired = true;
      }
      if (
        this.infoBuyer.email &&
        !this.infoBuyer.email.match(Constants.REGEX_EMAIL)
      ) {
        this.infoBuyer.emailInvalid = true;
      }
    },
    goPayment() {
      const data = this.$route.query.info
        ? JSON.parse(this.$route.query.info)
        : {};
      this.$router.push({
        name: this.$route.params.shopId ? "payment" : "payment domain",
        params: {
          id: this.id,
        },
        query: {
          info: JSON.stringify(data),
        },
      });
    },
  },
};
</script>
<style lang="scss">
.cart {
  background-color: #f5f5f5;
  min-height: calc(100vh - 280px);
  .cart-empty {
    width: 100%;
    min-height: calc(100vh - 280px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    button {
      background-color: #fe3834;
      color: #fff;
    }
  }
  .checkout {
    width: 100%;
    background: #fff;
    border-radius: 10px;
    .temp-price {
      width: 95%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .price-num {
        font-weight: 500;
      }
    }
    .prices-total {
      border-top: 1px solid #f2f2f2;
      .price {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .price-num {
          color: #fe3834;
          font-size: 28px;
          font-weight: 600;
          text-align: right;
          .vat {
            font-size: 16px;
            color: #000;
            font-weight: 500;
          }
        }
      }
    }
  }
  .checkout-btn {
    width: 100%;
    border-radius: 10px;
    font-size: 24px;
    font-weight: 600;
    color: #fff;
    background-color: #fe3834;
  }
}
.checkout-screen {
  background-color: #f5f5f5;
  padding-bottom: 10px;
  .card {
    background: #7a95c6;
    color: white;
    justify-content: center;
    margin: auto;
  }
  #stripe-element-mount-point {
    border: 1px solid blue;
  }
  .checkout {
    width: 100%;
    background: #fff;
    border-radius: 10px;
    .temp-price {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .price-num {
        font-weight: 500;
      }
    }
    .prices-total {
      border-top: 1px solid #f2f2f2;
      .price {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .price-num {
          color: #fe3834;
          font-size: 28px;
          font-weight: 600;
          text-align: right;
          .vat {
            font-size: 16px;
            color: #000;
            font-weight: 500;
          }
        }
      }
    }
  }
  .custom-button-paypal {
    background: #ffc439;
    border-radius: 4px;
    width: 90%;
    margin: auto;
    &:hover {
      cursor: pointer;
      background: #fcb208;
    }
  }
  .checkout-btn {
    width: 30%;
    border-radius: 10px;
    font-size: 17px;
    font-weight: 600;
    color: #fff;
    background-color: #635bff;
  }
  .cancel-btn {
    width: 30%;
    border-radius: 10px;
    font-size: 17px;
    font-weight: 600;
    color: #fff;
    background-color: #fe3834;
  }
  .showmore-btn {
    width: auto;
    border-radius: 10px;
    font-size: 17px;
    font-weight: 600;
    color: #fff;
    background-color: #e19c9b;
  }
  .input-card {
    margin-left: 40px;
    .check-box-card {
      margin: 5px;
      margin-right: 10px;
    }
    .select-card {
      width: 240px;
    }
  }
}
.model-foote {
  margin-top: 10px;
}
.hover:hover {
  cursor: pointer;
}
.fd-admin-listproperty-card-filter {
  position: relative;
  label {
    display: inline;
    border: none;
  }
  button {
    padding: 0;
    padding-bottom: 4px;
    box-shadow: none !important;
  }
  .b-calendar-header {
    display: none;
  }
  .b-calendar-nav {
    border: 1px solid #d8dbe0;
    background-color: #ffffff;
    border-color: #768192 !important;
    border-radius: 0.25rem;
    .btn-outline-secondary {
      color: #6c778a !important;
    }
  }
  .b-calendar-nav:focus {
    border-color: #768192 !important;
    box-shadow: none;
  }
  .b-calendar-grid:focus {
    border-color: #768192 !important;
    box-shadow: none;
  }
  .dropdown-menu {
    padding: 0;
    border: none;
  }
  .b-calendar-inner {
    position: absolute;
    top: 30px;
  }
}
.custom-button-paypal {
  background: #ffc439;
  border-radius: 4px;
  width: 90%;
  margin: auto;
  &:hover {
    cursor: pointer;
    background: #fcb208;
  }
}
.form-control {
  color: #000;
}
.form-control:focus {
  color: #000;
}
.info-payment {
  display: flex;
  @media (max-width: 992px) {
    display: block;
  }
  .personal {
    width: 50%;
    @media (max-width: 992px) {
      width: 100%;
    }
  }
  .currency {
    width: 50%;
    @media (max-width: 992px) {
      width: 100%;
    }
  }
}
.button-payment {
  &-desktop {
    @media (max-width: 992px) {
      display: none !important;
    }
  }
  &-mobile {
    @media (min-width: 993px) {
      display: none !important;
    }
  }
}
.text-terms:hover {
  cursor: pointer;
}

#purchase {
  clear: both;
  width: 100%;
  margin: 0 auto;
  font-size: 14px;
  line-height: 1.5;
  background: #fff;
  .floatingWrap {
    padding: 30px 0px 60px;
    .row {
      // display: block !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
    .col-lg-12 {
      margin-left: 0 !important;
      margin-right: 0 !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .col-lg-6:first-child {
      padding-left: 0 !important;
    }
    .row.policyContent {
      display: inline-block;
      width: 515px;
      @media (max-width: 575px) {
        width: 100%;
      }
    }
    .btnSubmit {
      width: 515px;
      margin: 30px 0 10px 0;
      @media (max-width: 575px) {
        width: 100%;
      }
    }
  }
  .ttl01 {
    font-size: 20px;
    border-bottom: 1px solid #d1d2d4;
    margin: 5px 0 30px;
    font-weight: normal;
    span {
      display: inline-block;
      padding: 0 10px 5px;
      border-bottom: 1px solid #424b55;
      position: relative;
      bottom: -1px;
      margin-top: 20px;
      font-size: 20px;
    }
  }
  .inputArea {
    .row {
      margin-top: 10px;
      .necessary {
        display: inline-block;
        background: #f55747;
        width: 40px;
        height: 20px;
        line-height: 20px;
        color: #fff;
        font-size: 12px;
        text-align: center;
        letter-spacing: 0.2em;
        margin: 0 0 0 10px;
        position: relative;
        top: -2px;
        transition: 0.5s linear;
      }
      .necessary::after {
        content: "必須";
      }
    }
    div {
      .nameWrap {
        width: 50%;
        float: left;
      }
    }
  }
  .info-order {
    width: 515px;
    float: left;
    @media (max-width: 575px) {
      width: 100%;
    }
  }
  input.formParts {
    height: 50px;
    border: 1px solid #8f9298;
    padding: 0 10px;
    font-size: 16px;
    border-radius: 2px;
    width: 100%;
  }
  input.formParts[type="radio"] {
    padding: 0;
    margin: 0;
    width: 0;
    height: 0;
    border: none;
    opacity: 0;
    font-size: 0;
    position: absolute;
  }
  section + section {
    margin: 20px 0 0;
  }
  .detail-order {
    width: 370px;
    float: right;
    @media (max-width: 992px) {
      float: none !important;
      width: 100% !important;
    }
  }
  button.btn01 {
    height: 70px;
    font-size: 20px;
    background: #97c71f;
    box-shadow: 0px 2px 0px 0px #759917;
  }
  button.formParts {
    font-family: "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ",
      Meiryo, "ＭＳ Ｐゴシック", sans-serif;
    width: 100%;
    color: #fff;
    text-align: center;
    font-weight: bold;
    display: inline-block;
    border-radius: 2px;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
  }
  .text-btn-purchase {
    font-size: 12px;
    margin-top: 20px;
    width: 515px;
    @media (max-width: 575px) {
      width: 100%;
    }
  }
  .erroTxt {
    clear: both;
    margin: 5px 0 0;
    color: #f86261;
    font-weight: bold;
  }
  button.btn02 {
    height: 50px;
    font-size: 16px;
    color: #666 !important;
    background: #dbdfe2;
    box-shadow: 0px 2px 0px 0px #b4bbc0;
  }
}
.floatingWrap {
  position: relative;
  width: 100%;
  min-height: 600px;
  section {
    .inner {
      margin: unset !important;
      width: 100%;
      padding: unset !important;
    }
  }
  .floatingBox {
    h2 {
      background: #424b55;
      color: #fff;
      font-size: 16px;
      text-align: center;
      line-height: 1;
      padding: 12px 0;
      margin: 0px;
    }
    .sub {
      padding: 10px 20px 0;
      dl {
        // overflow: hidden;
        clear: both;
        dt {
          float: left;
          font-weight: 100;
        }
        dd {
          margin: 0 0 0 8em;
          font-weight: bold;
        }
      }
    }
    .excludingTax {
      font-size: 12px;
    }
    .total {
      padding: 15px;
      margin: 10px 0;
      background: #e6e8ea;
      // overflow: hidden;
      font-weight: bold;
      dt {
        float: left;
        font-weight: bold;
      }
      dd {
        float: right;
        text-align: right;
        color: #d45452;
        font-size: 16px;
      }
    }
    .notes {
      padding: 5px 10px 0;
      font-size: 12px;
      color: #666;
    }
  }
  .floatingBoxInner {
    border: 1px solid #bfc0c4;
    border-top: none;
    padding: 0 0 10px;
  }
  .detail-order {
    position: sticky;
    top: 20px;
    @media (max-width: 992px) {
      position: relative;
    }
  }
}
.paymentContent {
  .tabBtn {
    // overflow: hidden;
    position: relative;
    z-index: 2;
    font-size: 0;
    text-align: center;
    list-style: none;
    li {
      font-size: 16px;
      background: #fff;
      display: block;
      float: left;
      input[type="radio"] + label {
        box-sizing: border-box;
        display: block;
        padding: 0 20px;
        span {
          display: inline-block;
          padding: 0 0 0 30px;
          height: 50px;
          line-height: 50px;
          background: url(../../assets/img/icon_radio01.png) no-repeat left
            center;
        }
      }
      input[type="radio"]:checked + label {
        span {
          background: url(../../assets/img/icon_radio02_on.png) no-repeat left
            center;
        }
      }
    }
  }
}
.hide {
  display: none !important;
}
.purchase-policy {
  a {
    font-weight: bolder;
    text-decoration-line: underline !important;
    color: #000;
    text-decoration: none !important;
  }
}
.body-payment {
  min-height: auto !important;
  margin: 0 auto;
  width: 980px;
  @media (max-width: 992px) {
    width: 720px;
  }
  @media (max-width: 767px) {
    width: 540px;
  }
  @media (max-width: 575px) {
    width: 100%;
  }
}
.bread-drum {
  position: absolute;
  right: 0;
  top: 50%;
  @media (max-width: 575px) {
    right: auto;
    top: 100%;
  }
  img {
    @media (max-width: 767px) {
      width: 250px;
    }
  }
}
.confirmContent {
  table {
    width: 100%;
    border-collapse: collapse;
    border-top: 1px solid #bfc0c4;
    border-left: 1px solid #bfc0c4;
    border-right: 1px solid #bfc0c4;
    th {
      width: 143px;
      padding: 14px 20px;
      text-align: left;
      font-weight: normal;
      color: #666;
      border-collapse: collapse;
      border-bottom: 1px solid #bfc0c4;
      vertical-align: middle;
    }
    td {
      padding: 14px 20px 14px 0;
      border-collapse: collapse;
      border-bottom: 1px solid #bfc0c4;
      vertical-align: middle;
    }
  }
}
.btnBack {
  margin: 15px 0px;
  width: 145px;
}
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #000;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.footer .row:nth-child(2) ul.footer-list .footer-item {
  display: none;
}
.footer .margin-content {
  max-width: 980px !important;
}
</style>
